.slider__wrapper {
  position: relative;
  overflow: visible;
  .slick-track {
    margin-left: 0;
  }
  .slider__arrows {
    position: relative;
    .slider__arrow-button {
      background: #686a70;
      border-radius: 18px;
      border: none;
      height: 28px;
      width: 28px;
      color: #fff;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      // &:hover {
      //   background: blue;
      // }
    }

    .arrow__disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      background-color: #e1e1e2;
    }
  }
  .slider__arrows__primary {
    .slider__arrow-button {
      border-radius: 4px;
      border: 1px solid #1e7fd9;
      background: #d4ebff;
      height: 40px;
      width: 40px;
      color: #1e7fd9;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      &:hover {
        background: #1e7fd9;
        color: #fff;
      }
    }

    .arrow__disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .slick-dots {
    // margin-top: 2rem !important;
    position: relative !important;
    li button:before {
      font-size: 14px !important;
      color: #999999;
    }
    .slick-active button:before {
      color: rgb(var(--color-primary));
      font-size: 18px !important;
    }
  }
}
